import React from 'react';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="text-white bg-green py-5gut flex flex-col sm:flex-row">
      <div className="container px-gut mx-auto mb-2gut">
        <h1 className="hd-4xl mb-3gut">Not Found</h1>
        <p className="my-gut hd-xl">Likely a problem on our end. We&apos;ll look into it.</p>
        <p className="my-gut hd-xl">
          In the meantime, maybe what you&apos;re looking for is on our{' '}
          <a href="/docs/get-started/getting-started/" className="font-bold">Docs</a>{' '}
            or <a href="/docs/api-reference/" className="font-bold">API Reference</a>
        </p>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
